;(function ($) {
    function getUrlVars(param) {
        var res = {},
            loc = location.href,
            startPos = loc.indexOf('?') != -1 ? loc.indexOf('?') : loc.indexOf('#') != -1 ? loc.indexOf('#') : false;

        if (startPos !== false) {
            loc
                .slice(startPos + 1)
                .replace(/[?#]/, "&")
                .split("&")
                .forEach(function (elem) {
                    elem = elem.split("=");

                    res[elem[0]] = elem[1] || true;
                });
        }

        return param ? res[param] || null : res;
    }

    function term(terms) {
        if (!terms[0]) {
            return "";
        }

        var res = terms[0];

        terms.forEach(function (item) {
            if (!res["parent"] && item["parent"]) {
                res = item;
            }
        });

        return res["name"];
    }

    function hexColorPlus(c1, c2, hover) {

        function colorSplit(color) {
            var res = [], c, len = color.length / 3;


            for (c = 0; c < 3; c++) {
                res.push(parseInt("0x" + color.substring(c * len, c * len + len), 16));
            }

            return res;
        }

        var co1 = colorSplit(c1),
            co2 = colorSplit(c2),
            co3 = colorSplit((hover ? "0f0f0f" : "000000")),
            res = [],
            tmp;

        for (item in co1) {
            tmp = co1[item] - co2[item] - co3[item];
            tmp = (tmp < 0 ? 0 : (tmp > 255 ? 255 : tmp)).toString(16);
            res.push((tmp.length < 2 ? "0" : "") + tmp);
        }


        return res.join("");
    }

    function getQuery() {
        $('head')
            .append(
                $('<style>').attr('id', 'map_box_colors')
            );

        $.getJSON(ajaxurl, {action: 'get_map_box'})
            .then(function (response) {
                var item, i, css, side, color,
                    colorsList = [],
                    colors = $('#map_box_colors'),
                    sides = {
                        "top": "000000",
                        "side": "1a1515",
                        "front": "2d221f"
                    };

                for (i = 0; i < response.length; i++) {
                    item = response[i];
                    color = item.color ? item.color.replace("#", "") : "CCE6ED";

                    if (item.color && colorsList.indexOf(item.color) === -1) {
                        colorsList.push(item.color);

                        css = "";
                        for (side in sides) {
                            css += ".outlet__" + side + ".color_" + color + ".active {\n";
                            css += "    fill: #" + (hexColorPlus(color, sides[side])) + " !important;\n";
                            css += "}\n";
                            css += ".outlet__" + side + ".color_" + color + ".active.hover {\n";
                            css += "    fill: #" + (hexColorPlus(color, sides[side], true)) + " !important;\n";
                            css += "}\n";
                        }
                        css += "\n\n";

                        colors.text(colors.text() + css);
                    }


                    if (item['map_box_data'] && item['map_box_data'][0]) {
                        if ($(".floor .shop").closest(".map_box_site").length) {
                            (function (__item) {
                                var item = __item;

                                $(".outlet__" + item['map_box_data'][0])
                                    .on("click", function (e) {
                                        var mobileTooltip = $("#mobileTooltip");
                                        mobileTooltip
                                            .html("")
                                            .addClass("show")
                                            .append(
                                                $("<span>")
                                                    .addClass("close")
                                                    .text("x")
                                                    .on("click", function () {
                                                        $("#mobileTooltip")
                                                            .removeClass("show")
                                                    })
                                            )
                                            .append(
                                                $("<div>")
                                                    .addClass("tooltip-shop-name")
                                                    .append(
                                                        $("<a>")
                                                            .attr("href", item["permalink"])
                                                            .html(item["title"])
                                                    )
                                            )
                                            .append(
                                                $("<div>")
                                                    .addClass("tooltip-descr")
                                                    .text(term(item['terms']))
                                            );

                                        if (item['renter_phone'].length) {
                                            mobileTooltip
                                                .append(
                                                    $("<div>")
                                                        .addClass("tooltip-phone")
                                                        .text(item['renter_phone'][0])
                                                );
                                        }

                                        mobileTooltip
                                            .append(
                                                $("<div>")
                                                    .addClass("tooltip-schedule")
                                                    .text("10:00 - 22:00")
                                            );
                                    })
                            })(item);

                            $(".outlet__" + item['map_box_data'][0])
                                .addClass("color_" + color)
                                .qtip({
                                    content: {
                                        text: (''
                                            + '<div class="tooltip-shop-name"><a href="' + item['permalink'] + '">' + item['title'] + '</a></div>'
                                            + '<div class="tooltip-descr">' + term(item['terms']) + '</div>'
                                            + ((item['renter_phone'].length) ? '<div class="tooltip-phone">' + item['renter_phone'][0] + '</div>' : '')
                                            + '<div class="tooltip-schedule">10:00 - 22:00</div>'
                                        )
                                        //, button: 'Close'
                                    },
                                    show: {
                                        event: 'click',
                                        solo: true
                                    },
                                    hide: 'unfocus',
                                    position: {
                                        my: 'bottom center',
                                        at: 'center center',
                                        target: $(".outlet__" + item['map_box_data'][0] + ".outlet__top")
                                    },
                                    style: {
                                        classes: 'qtip-dark qtip-rounded bubble'
                                    }
                                });

                            if (getUrlVars('openShop') == item.id) {
                                if (/b\d+/.test(item['map_box_data'][0])) {
                                    $(".floor.second .miniature .shop").click();
                                }
                                $(".outlet__" + item['map_box_data'][0]).click();
                            }
                        } else {
                            if (getUrlVars('post') == item['id']) {
                                $(".outlet__" + item['map_box_data'][0]).addClass("select");
                            } else {
                                $(".outlet__" + item['map_box_data'][0]).addClass("blocked");
                            }
                        }
                    }
                }
            });
    }


    $(function () {
        var regExpHover = /.*(outlet__(?:a|b)\d+).*/,
            outlet = "{wrap} .floor > .shop .outlet, {wrap} .floor > .logo .outlet",
            regExpWrap = /\{wrap}/g,
            openShop;


        $(outlet.replace(regExpWrap, '')).on("mouseover", function () {
            var className = $(this).getClasses(true).replace(regExpHover, "$1");

            $("." + className).not("blocked").addClass("hover");
        }).on("mouseout", function () {
            var className = $(this).getClasses(true).replace(regExpHover, "$1");

            $("." + className).removeClass("hover");
        });

        if ($(".map_box_site").length) {
            $(document).on("click", function () {
                $(".outlet").not("blocked").removeClass("select");
            });
        }

        $(outlet.replace(regExpWrap, '.map_box_site')).on("click", function (e) {
            e.stopPropagation();

            var className = $(this).getClasses(true).replace(/.*outlet__((?:a|b)\d+).*/, "$1");

            $(".outlet").not("blocked").removeClass("select");

            $(".outlet__" + className).not("blocked").addClass("select");
        });

        $(outlet.replace(regExpWrap, '.map_box_admin')).on("click", function () {
            if ($(this).hasClass("blocked")) {
                return false;
            }
            var className = $(this).getClasses(true).replace(/.*outlet__((?:a|b)\d+).*/, "$1");

            $(".outlet").not("blocked").removeClass("select");

            $(".outlet__" + className).not("blocked").addClass("select");

            $("#map_box_field").val(className);
            $("#map_box_field_show").text(className);
        });

        $(".miniature .shop").on("click", function () {
            var self = $(this).closest(".floor");

            if (self.hasClass('hide')) {
                $(".floor").toggleClass('hide');

                $(".map_box_site .btn")
                    .removeClass("active");

                if (self.hasClass("first")) {
                    $(".map_box_site .btn:first-child")
                        .addClass("active");
                } else {
                    $(".map_box_site .btn:last-child")
                        .addClass("active");
                }
            }
        });

        $(".map_box_site .btn").on("click", function (e) {
            var self = $(this);

            if (self.hasClass("first")) {
                $(".floor.first .miniature .shop").click();
            } else {
                $(".floor.second .miniature .shop").click();
            }
        });

        $("#features-lcd").on("click", function () {
            if ($(this).is(':checked')) {
                $(".legend").removeClass("hide");
            } else {
                $(".legend").addClass("hide");
            }
        });

        $('div.cube').each(function () {
            var self = $(this),
                color = self.attr('data-cube-color').replace('#', '');

            self
                .on("click", "> div", function () {
                    var self = $(this),
                        shops = $(".shop .color_" + color),
                        isFirstFloor = $('.map_box_site .btn.first').hasClass('active'),
                        isCurrentFloor = '[class*=outlet__' + (isFirstFloor ? 'a' : 'b') + ']',
                        hasShops = false;

                    shops.each(function () {
                        hasShops = hasShops || $(this).filter(isCurrentFloor).length;
                    });

                    if (!hasShops) {
                        $('.map_box_site .btn' + (isFirstFloor ? '.second' : '.first')).click();
                    }

                    if (self.hasClass("active")) {
                        shops.removeClass("active");
                        self.removeClass("active");
                    } else {
                        shops.addClass("active");
                        self.addClass("active");
                    }
                })
                .on("dblclick", "> div", function () {
                    var dblIsActive = $(this).hasClass("active");

                    $('div.cube').each(function () {
                        var self = $(this),
                            color = self.attr('data-cube-color').replace('#', ''),
                            shops = $(".shop .color_" + color);

                        if (dblIsActive) {
                            shops.removeClass("active");
                            self.find('> div').removeClass("active");
                        } else {
                            shops.addClass("active");
                            self.find('> div').addClass("active");
                        }
                    })
                })
                .find("polyline")
                .each(function () {
                    var self = $(this),
                        side = self.closest('g').getClasses()[0];

                    self
                        .addClass("outlet__" + side)
                        .addClass("color_" + color)
                        .addClass("active");
                });
        });

        if (openShop = getUrlVars('')) {

        }

        getQuery();
    });
})(jQuery);
